@import '../scss/volt/variables.scss';

.top-26 {
  top: 26% !important;
}
.input-group > .form-control:focus {
  z-index: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
footer {
  position: fixed !important;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 99;
  border-top: 1px solid #ebebeb;
  padding-left: 1%;
}
.img-list {
  border-radius: 100%;
  width: 28px;
  height: 28px;
  object-fit: contain;
}
//Scrollbar CSS Start
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  border-radius: 20px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #c3c3c4;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #eaecf0;
}
//Scrollbar CSS End
.customizebtn {
  font-size: 12px;
}
.custom-table-search {
  height: 37.59px;
}
.breadcrumb {
  background: linear-gradient(36deg, #fc3131, #fce8e7);
}
.breadcrumb-item,
.breadcrumb-item .nav-link {
  color: #ffffff;
}

.breadcrumb-item.active {
  color: #ffffff;
  font-weight: bold;
}
.sidebar .nav-link.active {
  color: #ffffff;
  cursor: pointer;
  background: #66799e;
}

.sidebar .nav-item.active > .nav-link{
  background: $theme-color;
}

.sidebar .nav-item .nav-link:hover{
  background: $theme-color-hover;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.sidebar-inner .accordion-item .nav-item .nav-link {
  font-size: 13px;
}
.badge {
  font-size: 12px;
  font-weight: 700;
}

.theme-color-600{
  background-image: url('/assets/Images/defaults/bg-img.png') !important;
  background-size: cover; /* Adjust this as needed */
  background-position: center; /* Adjust this as needed */
  background-repeat: no-repeat; /* Adjust this as needed */
}

.text-footer{
  color: $primary-theme-color !important;
}

.text-footer:hover , .text-dark{
  color: $theme-bg-color !important;
}

.text-dark:hover{
  color: $theme-bg-color-light !important;;
}

.auth-container{
  background-color:$auth-theme-color;
}

.btn-theme {
  --bs-btn-color: #ffffff;
  --bs-btn-bg:  #d62f2f ;
  --bs-btn-border-color: #E53636; // border color can match one of the gradient colors
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #E53636; // slightly darker gradient for hover
  --bs-btn-hover-border-color: #d62f2f; // darker variant for hover border
  --bs-btn-focus-shadow-rgb: 229, 54, 54; // RGB of one of the gradient colors
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: linear-gradient(45deg, #c12a2a, #850e05); // even darker gradient for active
  --bs-btn-active-border-color: #c12a2a; // darker variant for active border
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: linear-gradient(45deg, #E53636, #a81106); // same as normal for disabled
  --bs-btn-disabled-border-color: #E53636; // match one of the gradient colors for disabled
}

// .form-control{
//   border: 0.0625rem solid #ffae59;
// }

@keyframes loading-inner {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}.loading-inner div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: loading-inner 0.819672131147541s cubic-bezier(0,0.2,0.8,1) infinite;
}.loading-inner div:nth-child(1) {
  border-color: #a81106;
  animation-delay: 0s;
}
.loading-inner div:nth-child(2) {
  border-color: #e53636;
  animation-delay: -0.4098360655737705s;
}
.loading-spinner-ripple {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loading-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.loading-inner div { box-sizing: content-box; }
.insurance-details{
  .titles , .values{
    font-size: 14px;
  }
  .titles{
    font-weight: bold;
  }
}
.quotation-status{
  font-size: 14px;
    font-weight: bold;
}
.border-top-line{
  border-top: 1px solid #ebebeb;
}
.border-right-line{
  border-right: 1px solid #ebebeb;

}
.carousel-indicators [data-bs-target] {
  background-color: #000;
  border: 2px solid #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.carousel-indicators .active {
  background-color: #fff;
}
.carousel-indicators{
  top : 100%;
}